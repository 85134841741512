import React, { useState, ReactNode } from "react";
import "./Accordion.scss";
import IAgentService from "../../Services/AgentServices/IAgentService";
import AccordionFSItem from "./AccrodianFSItems";
import DataTable, { TableColumn } from "react-data-table-component";
import { LuEye } from "react-icons/lu";
import StatusLabel from "../StatusLabel/StatusLabel";
import AccordionWSItem from "./AccordianWSItems";
import AccordionDSItem from "./AccordianDSItems";
import AccordionGHItem from "./AccordianGHItems";
import { useLocation } from "react-router-dom";
import AccordionFAQItem from "./AccordianFAQItems";
import AccordionYTtem from "./AccordianYTItems";
import AccordionSKItem from "./AccordianSKItems";

interface SummaryProps {
  Assistantdata: IAgentService
}

const Summary: React.FC<SummaryProps> = ({ Assistantdata }) => {
  const [openTab, setOpenTab] = useState<string | null>(null);
 

  return (
    <div className="summary-accordion">
      <div className="accordion">
        <AccordionFSItem
          Data={Assistantdata.files}
          openTab={openTab}
          setOpenTab={setOpenTab}
        />
        <AccordionWSItem
          Data={Assistantdata.website}
          openTab={openTab}
          setOpenTab={setOpenTab}
        />
        <AccordionDSItem
          Data={Assistantdata.databaseConnections}
          openTab={openTab}
          setOpenTab={setOpenTab}
        />
        <AccordionGHItem
          Data={Assistantdata.github}
          openTab={openTab}
          setOpenTab={setOpenTab}
        />
      <AccordionFAQItem
          Data={Assistantdata.FAQ}
          openTab={openTab}
          setOpenTab={setOpenTab}
        />

      <AccordionYTtem
          Data={Assistantdata.youtube}
          openTab={openTab}
          setOpenTab={setOpenTab}
        />

       <AccordionSKItem
       Data={Assistantdata.slack}
       openTab={openTab}
       setOpenTab={setOpenTab}
     />
   </div>
    </div>
  );
};

export default Summary;
