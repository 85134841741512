import React, { SetStateAction, useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { toastError, toastSuccess, toastWarning } from "../Toast/Toast";
import { usePapaParse } from "react-papaparse";
import IAgentService from "../../Services/AgentServices/IAgentService";
import { LuCheck, LuChevronDown, LuPen, LuPlus, LuTrash, LuUpload } from "react-icons/lu";
import { FaTimes } from "react-icons/fa";


interface Props {
    UpdateAssistant: IAgentService
    SetUpdateAssistant: React.Dispatch<SetStateAction<IAgentService>>
    DisableSubmitButton: any
}
const FAQ: React.FC<Props> = ({ UpdateAssistant, SetUpdateAssistant, DisableSubmitButton }) => {
    const [openSection, setOpenSection] = useState<number | null>(0);
    const [QAFile, setAQFile] = useState<File>(new File([""], ""));
    const [IsQAFileButton, setIsQAFileButton] = React.useState<boolean>(true);
    const [showAddButtonQA, setShowAddbuttonQA] = useState(true);
    const [editedQuestion, setEditedQuestion] = useState("");
    const [selectedQuestion, setSelectedQuestion] = useState("");
    const [selectedAnswer, setSelectedAnswer] = useState("");
    const [showUplodPopup, setShowUplodPopup] = useState(false);


    const [isItemDisabledQA, setIsItemDisabledQA] = useState<boolean[]>([]);
    const [editedAnswer, setEditedAnswer] = useState("");
    const [unsavedChanges, setUnsavedChanges] = useState(false);

    const [editingIndexQA, setEditingIndexQA] = useState<number | null>(null);

    const { readString } = usePapaParse();

    useEffect(() => {
        if (UpdateAssistant.FAQ) {
            setIsItemDisabledQA(Array(UpdateAssistant.FAQ.length).fill(false));
        }
    }, [UpdateAssistant.FAQ]);

    const onChangeQAFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file: File = e.target.files ? e.target.files[0] : new File([''], '')
        if (file.type === 'text/csv') {
            setAQFile(file)
            setIsQAFileButton(false)

        } else {
            setIsQAFileButton(true)
            toastWarning("Invalid file type.Please choose files with supported formats.");
        }

    }

    const uploadQAFile = (e: React.FormEvent) => {
        e.preventDefault();
        const reader = new FileReader();
        let qaArray: any = [];
        let fError = false;
        let numOfQAExits = UpdateAssistant.FAQ?.length;
        reader.onload = function (e: any) {
            const text = e.target.result;
            readString(text, {
                worker: true,
                skipEmptyLines: true,
                complete: (results: any) => {
                    const duplicateQuestions: string[] = [];
                    for (let i = 0; i < results.data.length; i++) {
                        let data: any = results.data[i];
                        if (data?.length && data?.length === 2) {
                            // Check for duplicate questions
                            if (UpdateAssistant.FAQ?.some((item: any) => item.question === data[0])) {
                                duplicateQuestions.push(data[0]);
                            } else {
                                qaArray.push({ question: data[0], answer: data[1], Id: numOfQAExits ? numOfQAExits : 0 + i });
                            }
                        } else {
                            toastWarning("Invalid file type.Please choose files with supported formats.");
                            fError = true;
                            break;
                        }
                    }
                    if (!fError) {
                        if (duplicateQuestions.length > 0) {
                            console.log(`Retry,Duplicate questions found:\n${duplicateQuestions.join('\n')} `);
                            toastError("Duplicate questions have been detected. Please remove them and try your request again")
                        } else {
                            SetUpdateAssistant((prevState) => {
                                const updatedQandAList = [...(prevState.FAQ || []), ...qaArray]?.sort((a: any, b: any) => (b.Id - a.Id))
                                // const sortedQandAList = updatedQandAList.sort((a: any, b: any) => (a.Id > b.Id))
                                const newState = { ...prevState, FAQ: updatedQandAList };
                                setIsItemDisabledQA(Array(updatedQandAList.length).fill(false));
                                setShowAddbuttonQA(true);
                                return newState;
                            });
                            toastSuccess("File Uploaded successfully ");
                            DisableSubmitButton.current=false
                            setShowUplodPopup(false);
                            setIsQAFileButton(true);
                        }
                    }
                },
            });
        };
        reader.readAsText(QAFile);
    };


    const handleQandACreate = (target: any) => {
        let name = target.name;
        let val = target.value;
        if (name === "question") {
            setSelectedQuestion(val.trim())
        }
        if (name === "answer") {
            setSelectedAnswer(val.trim())
        }
        DisableSubmitButton.current = true
        setUnsavedChanges(val.length > 0);
    }


    const createQandA = () => {
        if (selectedQuestion && selectedAnswer) {
            // Check if the selected question already exists in QandAList
            if (UpdateAssistant.FAQ?.some((item: any) => item.question === selectedQuestion)) {
                toastError("Question already exists");
            } else {
                SetUpdateAssistant((prevState) => {
                    const updatedQandAList = [...(prevState.FAQ || []), { question: selectedQuestion, answer: selectedAnswer, Id: prevState.FAQ?.length }];
                    updatedQandAList?.sort((a: any, b: any) => (a.Id > b.Id ? -1 : Number(a.Id < b.Id)))
                    const newState = { ...prevState, FAQ: updatedQandAList };
                    return newState;
                });
                setSelectedQuestion("");
                setSelectedAnswer("");
                setShowAddbuttonQA(true);
                setIsItemDisabledQA((prev) => [...prev, false]);
                setUnsavedChanges(false);
                DisableSubmitButton.current = false
            }
        } else {
            toastError("Please enter both Question and Answer");
        }
    };



    const handleQandAUpdate = (e: any, index: number) => {
        let name = e.target.name;
        let val = e.target.value;

        if (name === "question") {
            setEditedQuestion(val);
        } else if (name === "answer") {
            setEditedAnswer(val);
        }
        DisableSubmitButton.current = true
        setUnsavedChanges(val.length > 0);
    };


    const updateQandAChange = (e: any, index: number) => {
        if (selectedQuestion && selectedAnswer) {
            if (editedQuestion) {
                if (UpdateAssistant.FAQ?.some((item: any, i: any) => i !== index && item.question === editedQuestion)) {
                    toastError("Question already exists");
                    return;
                }
            }
            SetUpdateAssistant((prevState) => {
                const updatedQandAList = [...(prevState.FAQ || [])];
                if (editedQuestion) {
                    updatedQandAList[index].question = editedQuestion;
                }
                if (editedAnswer) {
                    updatedQandAList[index].answer = editedAnswer;
                }
                const newState = { ...prevState, qAndAList: updatedQandAList };
                return newState;
            });
            setIsItemDisabledQA((prev: any) =>
                prev.map((value: any, i: any) => (i === index ? !value : value))
            );

            setEditingIndexQA(null);
            setEditedQuestion("");
            setEditedAnswer("");
            setUnsavedChanges(false);
            DisableSubmitButton.current = false;
        } else {
            toastError("Please enter both Question and Answer");
        }
    };



    const enableSelectedQandA = (index: number) => {
        setEditingIndexQA(index);
        setOpenSection(index)
        let qstn = UpdateAssistant.FAQ ? UpdateAssistant.FAQ[index].question : ""
        let ansr = UpdateAssistant.FAQ ? UpdateAssistant.FAQ[index].answer : ""
        setEditedQuestion(String(qstn));
        setEditedAnswer(String(ansr))
        setIsItemDisabledQA((prev: any) => {
            const updatedDisabled = [...prev.slice(0, index), !prev[index], ...prev.slice(index + 1)];
            return updatedDisabled;
        });
    };


    const addNewQandA = () => {
        setShowAddbuttonQA(false)
    }


    const deleteQandAConfirm = (index: number) => {
        confirmAlert({
            title: "Confirm Delete",
            message: "Do you want to delete the item ?",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => deleteQandA(index)

                },
                {
                    label: "No",
                    onClick: () => { }
                }
            ]
        })
    };



    const deleteQandA = (index: number) => {
        setIsItemDisabledQA((prev: any) =>
            prev.map((value: any, i: any) => (i === index ? false : value))
        );

        SetUpdateAssistant((prevState) => {
            const updatedQandA = [...(prevState.FAQ || [])];
            updatedQandA.splice(index, 1);
            return {
                ...prevState,
                FAQ: updatedQandA,
            };
        });
        DisableSubmitButton.current = false
    };

    // accordion start
    const handleSectionClick = (sectionIndex: number) => {
        setOpenSection((prevOpenSection) => (prevOpenSection === sectionIndex ? null : sectionIndex));
    };

    const hideUplodPopup = () => {
        setShowUplodPopup(false);
        setIsQAFileButton(true)

    }
    const viewUpload = () => {
        setShowUplodPopup(true);
    }
    return (<>
        <div className="commonDiv">
            <div className="titleBar d-flex align-items-center">
                <h3>FAQ <span>({UpdateAssistant.FAQ?.length})</span></h3>
                <div className="ms-auto">
                    <div className="d-flex gap-2">
                        {isItemDisabledQA.every(element => element === false) && <button
                            className="btn plainBtn"
                            type="button"
                            onClick={() => viewUpload()}
                            title="Upload FAQ"
                        >
                            <LuUpload />
                        </button>}

                        <button
                            className="btn plainBtn"
                            type="button"
                            title="Add New FAQ"
                            onClick={() => addNewQandA()}
                        >
                            <LuPlus />
                        </button>
                    </div>
                </div>

            </div>
            {showUplodPopup && (
                <div className="popup-container small-popup">
                    <div className="shadeClose" onClick={() => {
                        hideUplodPopup();
                    }}></div>
                    <div className="popup-inner">
                        <div className="inner-design">
                            <div className="popup-header">
                                <h3>Upload FAQ File</h3>
                                <button
                                    type="button"
                                    onClick={() => {
                                        hideUplodPopup();
                                    }}
                                    className="close"
                                ><FaTimes /></button>
                            </div>

                            <div className="popup-content">
                                <form >
                                    <div className="col-12 col-md-12 formHolder nameHolder mb-4">
                                        <label className="form-label">
                                            Name <span className="danger">*</span>
                                        </label>
                                        <input
                                            type="file"
                                            placeholder="Enter the name"
                                            name="Name"
                                            className="form-control"
                                            accept={".csv"}
                                            onChange={(e) => onChangeQAFile(e)}

                                        />
                                         {/* <p>Supports:.csv</p> */}
                                    </div>
                                    <div className="d-flex gap-2 mt-2">
                                        <button type="submit" className="btn btn-primary small" onClick={(e) => uploadQAFile(e)} disabled={IsQAFileButton}>
                                            Upload
                                        </button>
                                        <button
                                            type="submit"
                                            className="btn btn-secondary small"
                                            onClick={() => {
                                                hideUplodPopup();
                                            }}
                                        >
                                            Cancel
                                        </button>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <div className="content qaContent" >
                <div className="formContainer">
                    {(showAddButtonQA === false || (UpdateAssistant.FAQ && UpdateAssistant.FAQ.length === 0)) && (

                        <div className="repeatDiv mb-3" >
                            <div className="qstnHolder mb-2">
                                <input
                                    type="text"
                                    placeholder="Please enter the Question"
                                    name="question"
                                    className="form-control"
                                    onChange={(event) => handleQandACreate(event.target)}
                                />
                                <div className="btnHolder">
                                    {selectedQuestion !== "" && selectedAnswer !== "" && (
                                        <button
                                            type="button"
                                            className="btn plainBtn sbmtBtn"
                                            title="Create Save"
                                            onClick={() => createQandA()}
                                        >
                                            <LuCheck />
                                        </button>
                                    )}
                                </div>
                            </div>

                            <div className="aswrHolder">
                                <textarea
                                    className="form-control"
                                    name="answer"
                                    placeholder="Enter the Answer"
                                    onChange={(event) => handleQandACreate(event.target)}
                                />
                            </div>
                        </div>
                    )}

                    <div className="accordionContainer">
                        {UpdateAssistant.FAQ && UpdateAssistant.FAQ.map((item: any, index: any) => (
                            <div key={item.question} className="accordion-section repeatDiv mb-3">
                                <div className={`qstnHolder mb-2 accordion-header open ${index === openSection ? 'open' : ''}`}>
                                    <input
                                        type="text"
                                        placeholder="Please enter the Question"
                                        name="question"
                                        className="form-control"
                                        defaultValue={item.question}
                                        disabled={!isItemDisabledQA[index]}
                                        onChange={(e) => handleQandAUpdate(e, index)}
                                    />
                                    <div className="btnHolder">
                                        {(!isItemDisabledQA[index]) && (
                                            <button
                                                type="button"
                                                className="btn plainBtn editBtn"
                                                title="Edit"
                                                onClick={() => enableSelectedQandA(index)}
                                                disabled={editingIndexQA !== null && editingIndexQA !== index}
                                            >
                                                <LuPen />
                                            </button>
                                        )}

                                        {isItemDisabledQA[index] && (
                                            <button
                                                type="button"
                                                className="btn plainBtn sbmtBtn"
                                                title="Edit Save"
                                                onClick={() => updateQandAChange({ target: { value: editedQuestion } } as any, index)}
                                            >
                                                <LuCheck />
                                            </button>
                                        )}

                                        <button
                                            type="button"
                                            className="btn plainBtn deleteBtn"
                                            title="Delete"
                                            onClick={() => deleteQandAConfirm(index)}
                                        >
                                            <LuTrash />
                                        </button>

                                    </div>
                                    <div className="accordionTrgr" onClick={() => handleSectionClick(index)}>
                                        <LuChevronDown />
                                    </div>
                                </div>
                                {index === openSection && (


                                    // <div className="accordion-content aswrHolder">
                                    <textarea
                                        className="form-control"
                                        name="answer"
                                        placeholder="Enter the Answer"
                                        disabled={!isItemDisabledQA[index]}
                                        defaultValue={item.answer}
                                        onChange={(e) => handleQandAUpdate(e, index)}
                                    />
                                    // </div>


                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    </>)
}
export default FAQ
