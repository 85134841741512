import React, { useEffect, useRef, useState } from "react";
import './chatOption.scss'
import AgentService from "../../../../Services/AgentServices/AgentService";
import IAgentService from "../../../../Services/AgentServices/IAgentService";
import { useSearchParams } from "react-router-dom";
import { toastError, toastSuccess } from "../../../Toast/Toast";
import { ThreeDotLoader } from "../../../Loader/Loader";
import { ASSISTANT_INITIAL_OBJ } from "../../../../Constants/constants";

interface Props {
    SetUpdateAssistant: React.Dispatch<React.SetStateAction<IAgentService>>
    UpdateAssistant: IAgentService
    SetDisableButton: React.Dispatch<React.SetStateAction<boolean>>
}
const ChatOption: React.FC<Props> = ({ SetUpdateAssistant, UpdateAssistant, SetDisableButton }) => {
    const [chatOption, setChatOptions] = useState<string>("")
    const [chatType, setChatType] = useState<string>("")
    const [chatOptionList, setChatOptionsList] = useState<any>({
        "Document": ["Basics", "Advanced", "Analysis"],
        "Chat": null,
        "Images": null,
        "Excel": null,
        "SQL": null,
        "Github": null,
        "Slack":null,
        "Youtube":null
    })


    useEffect(() => {
        if (UpdateAssistant.chatOption?.option) {
            setChatOptions(UpdateAssistant?.chatOption?.option)

        }
        if (UpdateAssistant.chatOption?.RagType) {
            setChatType(UpdateAssistant?.chatOption?.RagType)
        }
    }, [UpdateAssistant])


    const queryTypeChanage = (e: React.ChangeEvent<HTMLSelectElement>) => {
        let value = e.target.value
        let name = e.target.name
        if (!value) {
            SetDisableButton(true)
            toastError("Please Select Chat Option")
        } else {
            SetDisableButton(false)
            if (name === "chatOption") {
                SetUpdateAssistant((preVal) => ({
                    ...preVal,
                    chatOption: {
                        option: value,
                        RagType: value === "Document" ? (chatType ? chatType : "Basics") : ''

                    }
                }))
                setChatOptions(value)
                if (chatOption !== "Document") {
                    setChatType('')
                }
            } else {
                SetUpdateAssistant((preVal) => ({
                    ...preVal,
                    chatOption: {
                        option: chatOption,
                        RagType: value

                    }
                }))
                setChatType(value)
            }
        }

    }


    return (<>
        <div className="chatOption-holder">
            <h2 className="mb-3">Chat Option</h2>
            <div className="chatOption-listing">

                <div className="chatoption">
                    <select className="form-select form-select-lg mb-3" onChange={(e) => queryTypeChanage(e)} value={chatOption} name="chatOption">
                        <option value="">Select chat option</option>
                        {Object.keys(chatOptionList).map(item => (
                            <option value={item}>{item}</option>
                        ))}
                    </select>


                    {chatOption === "Document" && <>
                        <select className="form-select form-select-lg mb-3" onChange={(e) => queryTypeChanage(e)} value={chatType} name="chatType">
                            {chatOptionList?.Document?.map((item: any, index: number) => (<>
                                {index === 0 ? (<option value={item} selected>{item}</option>) :
                                    <option value={item} >{item}</option>}
                            </>))}
                        </select>
                    </>
                    }

                </div>
            </div>

        </div>
        {/* <ThreeDotLoader visibility={showLoader}></ThreeDotLoader> */}

    </>)
}
export default ChatOption