import React, { useContext, useState, useEffect } from "react";
import './Sources.scss'
import IAgentService from "../../../Services/AgentServices/IAgentService";
import AgentService from "../../../Services/AgentServices/AgentService";
import { toastError, toastSuccess } from "../../Toast/Toast";
import { ThreeDotLoader } from "../../Loader/Loader";
import { LuTrash } from "react-icons/lu";
import { FaCircleCheck } from "react-icons/fa6";
import { usePapaParse } from "react-papaparse";
import { confirmAlert } from "react-confirm-alert";
import { useSearchParams } from "react-router-dom";
import UserContext from "../../../Context/UserContext/UserContext";
import IUser from "../../../Services/TokenService/IUser";
import { useSelector, useDispatch } from "react-redux";
import TemplateService from "../../../Services/TemplateService/TemplateService";
import ITemplateService, { } from "../../../Services/TemplateService/ITemplateService";
import { deleteTemplateData } from "../../../Stores/TemplateStore/TemplateAction";
import { ASSISTANT_INITIAL_OBJ } from "../../../Constants/constants";
import GitHub from "../../GItHub/GitHub";
import { IPermissions } from "../../../Services/RoleManagement/IRoleManagementService";
import PermissionContext from "../../../Context/PermissionContext/PermissionContext";
import UploadFiles from "../../Files/HandleFIles";
import Summary from "../../Summary/Summary";
import { ISAgentFile } from "../../Utils/Utils";
import Database from "../../Database/Database";
import StatusProps from "../StatusProps/StatusProps";
import Slack from "../../Slack/Slack";
import Youtube from "../../Youtube/Youtube";
import Website from "../../Website/Website";
import FAQ from "../../FAQ/FAQ";
interface Props {
  SetSelectedMainTab: React.Dispatch<React.SetStateAction<string>>;
  SelectedMainTab: string;
}

const Sources: React.FC<Props> = ({ SetSelectedMainTab }) => {
  const permission = useContext<IPermissions>(PermissionContext);

  const dispath = useDispatch();
  const [searchParams] = useSearchParams();
  const assId = searchParams.get("assistantId");
  const assistantId = React.useRef(assId ? assId : "");
  const templateId = useSelector((state: any) => state.templateReducer);

  const [selectedTab, setSelectedTab] = useState("files");
  const [assistant, setAssistant] = useState<IAgentService>();
  const [showLoader, setShowLoader] = useState(false);
  const [templateDetails, setTemplateDetails] = useState<ITemplateService>();
  const [deletedFileIds, setDeletedFileIds] = useState<Array<string>>([]);
  const [filesCount, setFilesCount] = useState(0);
  const [qaCount, setQACount] = useState(0);
  const [websiteCount, setWebsiteCount] = useState(0);
  const [databaseCount, setDatabaseCount] = useState(0);
  const disableSubmitButton = React.useRef<boolean>(true);
  const [isPopUp, setIsPopUp] = useState(false);
  const [showStatusPopup, setShowStatusPopup] = useState(false);

  const [updateAssistant, setUpdateAssistant] = useState<IAgentService>({
    ...ASSISTANT_INITIAL_OBJ,
  });



  useEffect(() => {
    const fetchData = async () => {
      if (!assistantId.current) return;
      await getAssistantById();
      if (templateId) {
        getTemplatesById(templateId);
      }
    };
    fetchData();
  }, []);



  useEffect(() => {
    // count
    setFilesCount(updateAssistant.files ? updateAssistant.files.length : 0);
    setQACount(
      updateAssistant.FAQ ? updateAssistant.FAQ.length : 0
    );
    setWebsiteCount(
      updateAssistant.website ? updateAssistant.website.length : 0
    );
  }, [
    updateAssistant.FAQ,
    updateAssistant.files,
    updateAssistant.website,
    databaseCount,
  ]);

  const getAssistantById = async () => {
    setShowLoader(true);
    AgentService.GetAssistantById(assistantId.current)
      .then((res) => {
        const data: IAgentService = res.response;
        setAssistant(data);
        setUpdateAssistant(() => ({
          ...data,
        }));
        localStorage.removeItem("assistantCreated")
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setShowLoader(false);
      });
  };

  const getTemplatesById = (templateId: string) => {
    setShowLoader(true);
    TemplateService.GetTemplateById(templateId)
      .then((res) => {
        const data: ITemplateService = res.response;
        setTemplateDetails(data);
        setUpdateAssistant((preVal) => ({
          ...preVal,
          ...data,
        }));
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setShowLoader(false);
      });
  };
  const UpdateSource = () => {
    if (!validateSourceData()) return;
    if (!permission.modifyAllAgents) return;
    setShowLoader(true);
    disableSubmitButton.current = true;
    AgentService.UpdateAssistant(
      assistantId.current,
      updateAssistant,
      deletedFileIds
    )
      .then((res) => {
        toastSuccess(res.response);
        // getAssistantById()
        setDeletedFileIds([]);
        // dispath(deleteTemplateData()); need to check further
        SetSelectedMainTab(() => {
          return "prompt"
        });
      })
      .catch((err) => {
        toastError(err?.response?.data?.response);
        console.log(err);
      })
      .finally(() => {
        setShowLoader(false);
        disableSubmitButton.current = false;
      });
  };



  const validateSourceData = () => {
    let flag = true

    // if (unsavedChanges) {
    //   toastError("Please save or discard the changes in the FAQ section before updating.");
    //   flag = false;
    // }


    if (updateAssistant.files && (updateAssistant.files.length > 20)) {
      toastError("Please select files up to 20.");
      flag = false;
    }

    const fileNames = updateAssistant.files?.map((file: any) => {
      return file.name && file.name.toLowerCase()
    });
    const uniqueFileNames = new Set(fileNames);
    if (fileNames && fileNames.length !== uniqueFileNames.size) {
      toastError("Duplicate file names are not allowed.");
      return false;
    }

    const questions = updateAssistant.FAQ?.map((item: any) => item.question);
    if (questions) {
      const uniqueQuestions = new Set(questions);
      if (questions.length !== uniqueQuestions.size) {
        toastError("Duplicate questions are not allowed.");
        return false;
      }
    }

    // files validation upload only one csv and excel at a time
    let exten: any[] = []
    updateAssistant.files?.map((file: any) => {
      if (!ISAgentFile(file)) {
        var ext = file.name.substr(file.name.lastIndexOf('.') + 1);
        exten.push(ext)
      }
    })


    const counts = exten.reduce((acc, name) => {
      acc[name] = (acc[name] || 0) + 1;
      return acc;
    }, {});

    if (counts['csv'] > 1 || counts['xlsx'] > 1 || counts['xls'] > 1) {
      toastError("Please upload only one .xlsx,.xls or .csv file at a time");
      flag = false;
    }

    return flag
  }


  const onChangeTab = (tab: string) => {
    setSelectedTab(tab);
  };


  ///////////////////////////

  const nextPrevious = (tab: any) => {
    if (tab === "Next") {
      SetSelectedMainTab("prompt");
    } else {
      SetSelectedMainTab("basicdetails");
    }
  };






  return (
    <>
      <div className="sourceHolder crb-source">
        <div className="source-tab">
          <div className="tabHeader">
            <div
              className={`tabButton ${selectedTab === "files" ? "active" : ""}`}
              onClick={() => onChangeTab("files")}
            >
              Files
            </div>
            <div
              className={`tabButton ${selectedTab === "website" ? "active" : ""}`}
              onClick={() => onChangeTab("website")}
            >
              Website
            </div>
            <div
              className={`tabButton ${selectedTab === "qna" ? "active" : ""}`}
              onClick={() => onChangeTab("qna")}
            >
              FAQ
            </div>

            <div
              className={`tabButton ${selectedTab === "ds" ? "active" : ""}`}
              onClick={() => onChangeTab("ds")}
            >
              Database
            </div>
            <div
              className={`tabButton ${selectedTab === "github" ? "active" : ""}`}
              onClick={() => onChangeTab("github")}
            >
              GitHub
            </div>
            <div
              className={`tabButton ${selectedTab === "youtube" ? "active" : ""}`}
              onClick={() => onChangeTab("youtube")}
            >
              Youtube
            </div>
            <div
              className={`tabButton ${selectedTab === "slack" ? "active" : ""}`}
              onClick={() => onChangeTab("slack")}
            >
              Slack
            </div>
            <div
              className={`summary-tab tabButton ${selectedTab === "summary" ? "active" : ""}`}
              onClick={() => onChangeTab("summary")}
            >
              Summary
            </div>
          </div>


          <div className="tabContent mb-4">
            <div className="topContent">

              {selectedTab === "files" && (
                <UploadFiles UpdateAssistant={updateAssistant}
                  SetUpdateAssistant={setUpdateAssistant}
                  SetDeletedFileIds={setDeletedFileIds}
                  DisableSubmitButton={disableSubmitButton} />
              )}
              {selectedTab === "website" && (
                <Website UpdateAssistant={updateAssistant}
                  SetUpdateAssistant={setUpdateAssistant}
                  DisableSubmitButton={disableSubmitButton} />
              )}
              {selectedTab === "qna" && (
                <FAQ UpdateAssistant={updateAssistant}
                  SetUpdateAssistant={setUpdateAssistant}
                  DisableSubmitButton={disableSubmitButton} />
              )}

              {selectedTab === "ds" && (
                <Database SetUpdateAssistant={setUpdateAssistant}
                  UpdateAssistant={updateAssistant}
                  DisableSubmitButton={disableSubmitButton} />
              )}

              {selectedTab === "github" && (
                <GitHub
                  SetAssistantData={setUpdateAssistant}
                  AssistantData={updateAssistant}
                  DisableSubmitButton={disableSubmitButton}
                />
              )}

              {selectedTab === "summary" && (
                <Summary Assistantdata={updateAssistant} />
              )}
              {selectedTab === "youtube" && (
                <Youtube
                  SetAssistantData={setUpdateAssistant}
                  AssistantData={updateAssistant}
                  DisableSubmitButton={disableSubmitButton} />
              )}
              {selectedTab === "slack" && (
                <Slack
                  SetAssistantData={setUpdateAssistant}
                  AssistantData={updateAssistant}
                  DisableSubmitButton={disableSubmitButton} />

              )}
            </div>
          </div>
        </div>



        <div className="mt-2 btnHolder d-flex gap-3">
          <button
            type="button"
            className="btn btn-secondary ms-auto"
            onClick={() => {
              nextPrevious("Previous");
            }}
          >
            Previous
          </button>

          {/* <button type="button" className="btn btn-primary" onClick={() => statusPopup()}>dummy</button> */}

          <button
            className="btn btn-primary"
            type="button"
            disabled={disableSubmitButton.current}
            onClick={() => UpdateSource()}
          >
            Save & Continue
          </button>


          {showStatusPopup && (
            <StatusProps />
          )}
        </div>
      </div >

      <ThreeDotLoader visibility={showLoader}></ThreeDotLoader>
    </>
  );
};
export default Sources;
