import React, { ChangeEvent, useState } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import ITemplateService, { ISlack } from "../../Services/TemplateService/ITemplateService";
import IAgentService from "../../Services/AgentServices/IAgentService";
import { toastError } from "../Toast/Toast";
import { LuPen, LuPlus, LuTrash } from "react-icons/lu";
import { FaTimes } from "react-icons/fa";
import { confirmAlert } from "react-confirm-alert";
interface Props {
    SetAssistantData: React.Dispatch<React.SetStateAction<ITemplateService | IAgentService>>
    AssistantData: IAgentService
    DisableSubmitButton: any
}
const Slack: React.FC<Props> = ({ SetAssistantData, AssistantData, DisableSubmitButton }) => {
    const [showFormSK, setShowFormSK] = useState(false)
    const [editingIndex, setEditingIndex] = useState<number|null>(null)
    const [createFormSK, setCreateFormSK] = useState<ISlack>({
        name: "",
        slackToken: "",
        channelId: ""
    })
    const handleCancel = () => {
        setShowFormSK(false)
        setEditingIndex(null)
    }
    const handleChangeForm = (e: ChangeEvent<HTMLInputElement>) => {
        let name = e.target.name
        let value = e.target.value
        setCreateFormSK((preVal) => ({ ...preVal, [name]: value }))
    }
    const handleShowSK = () => {
        setShowFormSK(true);
        setEditingIndex(null)
    };
    const validateForm = (dataArr:ISlack[]) => {
        let flag = true

        if (!createFormSK.name) {
            toastError("Please Enter Name");
            flag = false
        }
        if (!createFormSK.slackToken) {
            toastError("Please Enter Slack token");
            flag = false
        }


        if (!createFormSK.channelId) {
            toastError("Please Enter Slack ChannelId");
            flag = false
        }

        const NameUnique = new Set(dataArr.map(v => v.name));
        if (NameUnique.size !== dataArr?.length) {
            toastError("Slack name already exists");
            flag = false
        }

        return flag
    };
    const handleCreate = (action: string) => {
        SetAssistantData((preVal) => {
            let slack = preVal.slack
            if (action == "edit") {
                slack = preVal.slack?.filter((item, index) => index !== editingIndex)
            }
            slack = [...(slack || []), createFormSK]
            if (validateForm(slack)) {
                setShowFormSK(false)
                DisableSubmitButton.current = false
                return {
                    ...preVal,
                    slack: slack
                }
            } else {
                return {
                    ...preVal,
                    slack: preVal.slack
                }

            }
        })
        setEditingIndex(null)
    }
    const handleEditYoutube = (row:ISlack, index:number)=>{

        setCreateFormSK(row)
        setShowFormSK(true);
        setEditingIndex(index)
    }
    const handleRemoveSlack = (row:ISlack, index:number)=>{
        confirmAlert({
            title: "Confirm Delete",
            message: "Are you sure you want to delete this slack?",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => {
                        // Remove item from createTemplateData.DatabaseConnections
                        const slack = AssistantData.slack?.filter(
                            (item: any) => item.name !== row.name
                        );
                        DisableSubmitButton.current = false
                        SetAssistantData((prevData: any) => ({
                            ...prevData,
                            slack: slack,
                        }));
                    },
                },
                {
                    label: "No",
                    onClick: () => { },
                },
            ],
        });

    }
    const SKTableColumn: TableColumn<any>[] = [
        {
            name: "Name",
            selector: (row: any) => row.name,
            sortable: true,
            width: "250px",
            cell: (row: any) => (
                <div
                    style={{
                        maxWidth: "250px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                    }}
                    title={row.name}
                >
                    {row.name.length > 50
                        ? `${row.name.substring(0, 50)}...`
                        : row.name}
                </div>
            ),
        },
        {
            name: "Slack Token",
            selector: (row: any) => row.slackToken,
            sortable: true,
            width: "250px",
            cell: (row: any) => (
                <div
                    style={{
                        maxWidth: "250px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                    }}
                    title={row.slackToken}
                >
                    {row.slackToken.length > 25
                        ? `${row.slackToken.substring(0, 25)}...`
                        : row.slackToken}
                </div>
            ),
        },
        {
            name: "Channel Id",
            selector: (row: any) => row.channelId,
            sortable: true,
            width: "250px",
            cell: (row: any) => (
                <div
                    style={{
                        maxWidth: "250px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                    }}
                    title={row.channelId}
                >
                    {row.channelId.length > 25
                        ? `${row.channelId.substring(0, 25)}...`
                        : row.channelId}
                </div>
            ),
        },
        {
            name: " ",
            sortable: false,
            button: true,
            right: true,
            cell: (row: any, rowIndex: any) => (
                <>
                <div className="gridIcons" data-tag="allowRowEvents">
                    <>
                        <>
                            <button
                                type="button"
                                className="btn plainBtn deleteBtn"
                                title="Edit"
                                onClick={() => handleEditYoutube(row, rowIndex)}
                            >
                                <LuPen />
                            </button>
                        </>
                    </>
                </div>
                <div className="gridIcons" data-tag="allowRowEvents">
                <>
                    <>
                        <button
                            type="button"
                            className="btn plainBtn deleteBtn"
                            title="Delete"
                            onClick={() => handleRemoveSlack(row, rowIndex)}
                        >
                            <LuTrash />
                        </button>
                    </>
                </>
            </div>
            </>
            ),
        },
    ]
    return (<>
        <div className="commonDiv">
            <div className="titleBar d-flex align-items-center">
                <h3> Slack <span>({AssistantData.slack?.length})</span></h3>
                <div className="ms-auto">
                    <div className="d-flex gap-2">
                        <button
                            className="btn plainBtn ms-auto"
                            type="button"
                            onClick={() => handleShowSK()}

                        >
                            <LuPlus />
                        </button>
                    </div>
                </div>
            </div>
            <div className="dataTable">
                {AssistantData?.slack && (
                    <DataTable
                        noHeader={true}
                        columns={SKTableColumn}
                        data={
                            AssistantData?.slack ? AssistantData?.slack : []
                        }
                        // pagination
                        // paginationServer
                        pagination={true}
                        paginationPerPage={10}
                        paginationRowsPerPageOptions={[10, 15, 20]}
                    />
                )}
            </div>
            {showFormSK &&
                <div className="popup-container create-template-popup">
                    <div className="shadeClose" onClick={() => handleCancel()}></div>
                    <div className="popup-inner">
                        <div className="inner-design">
                            <div className="popup-header">
                                <h3>New Slack</h3>
                                <button type="button" onClick={() => handleCancel()} className="close"><FaTimes /></button>
                            </div>

                            <div className="popup-content">
                                <div className="row">
                                    <div className="col-12 col-md-5 formHolder nameHolder mb-4">
                                        <label className="form-label">
                                            Name <span className="danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            name="name"
                                            className="form-control"
                                            onChange={(event) => handleChangeForm(event)}
                                            value={createFormSK.name}
                                        />
                                    </div>
                                    <div className="col-12 col-md-5 formHolder nameHolder mb-4">
                                        <label className="form-label">
                                            Slack Token <span className="danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            name="slackToken"
                                            className="form-control"
                                            onChange={(event) => handleChangeForm(event)}
                                            value={createFormSK.slackToken}
                                        />
                                    </div>

                                    <div className="col-12 col-md-5 formHolder nameHolder mb-4">
                                        <label className="form-label">
                                            Channel Id <span className="danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            name="channelId"
                                            className="form-control"
                                            onChange={(event) => handleChangeForm(event)}
                                            value={createFormSK.channelId}
                                        />
                                    </div>






                                </div>



                                <div className="d-flex gap-2 mt-2">
                                    <button type="button" className="btn btn-secondary " onClick={() => handleCancel()}>Cancel</button>
                                    <button className="btn btn-primary ms-auto" onClick={() => handleCreate(editingIndex != null ? "edit" : "create")}>Done</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
        </div>
    </>)
}

export default Slack