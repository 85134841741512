import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { FaTrash } from 'react-icons/fa';
import { LuFolderOpen, LuTrash2 } from "react-icons/lu";
import { toastError, toastSuccess, toastWarning } from "../Toast/Toast";
import IGroupService from "../../Services/GroupService/IGroupService";
import { ThreeDotLoader } from "../Loader/Loader";
import { confirmAlert } from "react-confirm-alert";
import GroupService from "../../Services/GroupService/GroupService";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useDispatch } from "react-redux";
import { deleteTemplateData } from "../../Stores/TemplateStore/TemplateAction";
import IUser from "../../Services/UserManagement/IUserManagementService";
import UserContext from "../../Context/UserContext/UserContext";
import { IPermissions } from "../../Services/RoleManagement/IRoleManagementService";
import PermissionContext from "../../Context/PermissionContext/PermissionContext";
import CreateGroup from "./CreateGroup";
import ReactPaginate from "react-paginate";
import Search from "../Search/Search";
import "./Groups.scss"

// import { permissionState } from "../../Constants/constants";

const Groups = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate();

  const user = useContext<IUser>(UserContext)
  const permission = useContext<IPermissions>(PermissionContext)
  const [pageSize, setPageSize] = useState(12)
  const [pageNumber, setPageNumber] = useState(1)
  const [totalCount, setTotalCount] = useState(0)

  const [showGroupFormPopup, setShowGroupFormPopup] = useState(false);
  const [searchText, setSearchText] = useState("");

  const [groupList, setGroupList] = useState<IGroupService[]>([]);
  const [showLoader, setShowLoader] = useState(false);



  useEffect(() => {
    // if (!permission.viewGroup) return;
    getAllGroups()
  }, [showGroupFormPopup, pageNumber])

  useEffect(() => {
    if (searchText === "") getAllGroups()
  }, [searchText]);

  const getAllGroups = () => {
    if (!permission.viewGroup) return;
    setShowLoader(true);
    GroupService.GetGroupList(pageNumber, pageSize, searchText).then(res => {
      setGroupList(res.response);
      setTotalCount(res.count ? res.count : 0)
    }).catch(error => {
      console.log('GetAllGroups', error);
    }).finally(() => {
      setShowLoader(false);
    })
  };


  const handlePageClick = (event: any) => {
    setPageNumber(event.selected + 1)
  };

  const goChatBot = async (groupName?: string) => {
    if (groupName) {
      if (!permission.viewGroup) return;
      const navigationParams = {
        pathname: groupName ? '/Apps' : '/AgentDetails',
        search: groupName ? `groupName=${groupName}` : ''
      };
      dispatch(deleteTemplateData())
      navigate(navigationParams);

    }
    else {
      if (!permission.createGroup) return;
      // navigate('/Group/Create');
      setShowGroupFormPopup(true)
    }
  };



  const handleDeleteGroup = (gpName: string) => {
    if (!permission.deleteGroup) return;
    confirmAlert({
      title: "Confirm Delete",
      message: "Do you want to delete the group and its associated agents?",
      buttons: [
        {
          label: "Yes",
          onClick: () => GroupService.DeleteGroup(gpName).then((res) => {
            if (res) {
              setShowLoader(false)
              if (res.success === true) {
                toastSuccess("Group Deleted Successfully")
              } else {
                toastError("Group Deletion Failed")
                console.log('DeleteGroupAPI', res.response)
              }
              setShowLoader(false)
            } else {
              toastWarning("Response Error")
              setShowLoader(false)
            }
            getAllGroups()
          })

        },
        {
          label: "No",
          onClick: () => { }
        }
      ]
    })
  };

  const changeSearchText = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value
    setSearchText(value)
  }



  return (
    <div className="contentWrapper home-container">

      <div className="row">
        <div className="col-12 col-md-12 leftContainer">
          <div className="d-flex mb-3 align-items-center">
            <h2>All Groups({totalCount})</h2>
            {/* {permissionState.isCreateBot && ( */}
            <div className="ms-auto">
              <div className="d-flex gap-3">
                <div className='search'>
                  <input type='text' placeholder='Enter the group name' onChange={(e) => changeSearchText(e)} value={searchText}></input>
                  <button className='search-btn' type='button' onClick={() => getAllGroups()}>Search</button>
                </div>
                <button type="submit" className="ms-auto btn btn-secondary" disabled={!permission.createGroup} onClick={(e) => goChatBot()}>Create Group</button>
              </div>
            </div>

            {/* )} */}
          </div>
          {/* {showLogin && ( */}
          <div className="row flex-wrap">

            {Array.isArray(groupList) && groupList.length > 0 && groupList.map((group: IGroupService) => (
              <div className="col-12 col-md-3 col-lg-3 mb-4 boxHolder">
                <div className="box" onClick={(e) => goChatBot(group.name)} >
                  <div className="iconHolder">

                    {group.icon ? <img src={`data:${group.icon};base64,${group.icon}`} alt="" /> : <LuFolderOpen />}
                  </div>
                  <div className="textHolder">{group.name}</div>
                  {/* {permissionState.isDeleteGroup && ( */}
                  <div className="icons">
                    <button
                      className="close" title="Delete"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteGroup(group.name);
                      }}
                      disabled={!permission.deleteGroup}
                    >
                      <LuTrash2 />
                    </button>
                  </div>
                  {/* )} */}
                </div>
              </div>

            ))}
            {Array.isArray(groupList) && groupList.length === 0 && (<>
              <div className="col-12 col-md-12 mt-5">
                <p className="text-center"> There are no records to display</p>
              </div>
            </>)}
          </div>

          {totalCount > pageSize &&
            <ReactPaginate
              className="pagination"
              breakLabel="..."
              nextLabel=">"
              onPageChange={handlePageClick}
              pageCount={Math.ceil(totalCount / pageSize)}
              previousLabel="<"
              renderOnZeroPageCount={null}
            />
          }
        </div>
      </div>

      {showGroupFormPopup && <CreateGroup
        SetShowGroupFormPopup={setShowGroupFormPopup} />}
      <ThreeDotLoader visibility={showLoader}></ThreeDotLoader>
    </div>
  );
};


export default Groups;
