import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "./Home.scss";
import { toastSuccess } from "../Toast/Toast";
import { ThreeDotLoader } from "../Loader/Loader";
import { confirmAlert } from "react-confirm-alert";
import IAgentService from "../../Services/AgentServices/IAgentService";
import AgentService from "../../Services/AgentServices/AgentService";
import "react-confirm-alert/src/react-confirm-alert.css";
import { LuTrash2 } from "react-icons/lu";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { deleteTemplateData } from "../../Stores/TemplateStore/TemplateAction";
import IUser from "../../Services/UserManagement/IUserManagementService";
import UserContext from "../../Context/UserContext/UserContext";
import { IPermissions } from "../../Services/RoleManagement/IRoleManagementService";
import PermissionContext from "../../Context/PermissionContext/PermissionContext";
import ReactPaginate from "react-paginate";
import Search from "../Search/Search";
import IGroupService from "../../Services/GroupService/IGroupService";
import GroupService from "../../Services/GroupService/GroupService";

const Home = () => {
  const user = useContext<IUser>(UserContext);
  const permission = useContext<IPermissions>(PermissionContext);

  const dispath = useDispatch();
  const [showLoader, setShowLoader] = useState(false);
  const [showAgentList, setShowAgentList] = useState(true);
  const [agentList, setAgentList] = useState<IAgentService[]>([]);
  const [searchParams] = useSearchParams();
  const [pageSize, setPageSize] = useState(12)
  const [pageNumber, setPageNumber] = useState(1)
  const [totalCount, setTotalCount] = useState(0)
  const groupName = useRef(searchParams.get("groupName"));
  const [selectedGroupName, setSelectedGroupName] = useState(groupName.current ? groupName.current : "");
  const [searchText, setSearchText] = useState("");
  const [groupList, setGroupList] = useState<IGroupService[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    // if (!permission.viewAllAgents) return;
    getAssistanceList();
    getAllGroups()
  }, [pageNumber]);

  useEffect(() => {
    // if (!permission.viewAllAgents) return;
    getAssistanceList();
    getAllGroups()
  }, [selectedGroupName]);


  useEffect(() => {
    if (searchText === "") getAssistanceList()
  }, [searchText]);


  const getAssistanceList = () => {
    if (!permission.viewAllAgents) return;
    setShowLoader(true);
    AgentService.GetAssistantList(selectedGroupName ? selectedGroupName : "", pageSize, pageNumber, searchText)
      .then((res) => {
        setAgentList(res.response);
        setTotalCount(res.count ? res.count : 0)
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setShowLoader(false);
      });
  };

  const getAllGroups = () => {
    if (!permission.viewGroup) return;
    setShowLoader(true);
    GroupService.GetGroupList().then(res => {
      setGroupList(res.response);
      // setTotalCount(res.count ? res.count : 0)
    }).catch(error => {
      console.log('GetAllGroups', error);
    }).finally(() => {
      setShowLoader(false);
    })
  };

  const handleDeleteAssistant = (assistantId: string) => {
    if (!permission.deleteAgent) return;
    confirmAlert({
      title: "Confirm Delete",
      message: "Do you want to delete the agent?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            setShowLoader(true);
            AgentService.DeleteAssistant(assistantId)
              .then((res) => {
                toastSuccess(res.response);
              })
              .catch((err) => {
                console.log(err);
              })
              .finally(() => {
                setShowLoader(false);
                getAssistanceList();
              });
          },
        },
        {
          label: "No",
          onClick: () => { },
        },
      ],
    });
  };

  const handlePageClick = (event: any) => {
    setPageNumber(event.selected + 1)
  };

  const goChatBot = async (assistant: IAgentService | null) => {
    if (!permission.createAgent) return;
    dispath(deleteTemplateData());
    navigate({
      pathname: "/AgentDetails",
      search: assistant ? `?assistantId=${assistant?.id}` : "",
    });
  };
  const changeSearchText = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value
    setSearchText(value)
    
  }

  const changeSearchGroup = (e: React.ChangeEvent<HTMLSelectElement>) => {
    let value = e.target.value
    setSelectedGroupName(value)
    if (value === "") getAssistanceList()
  }



  return (
    <div className="contentWrapper home-container">
      {showAgentList && (
        <>
          <div className="row">
            <div className="col-12 col-md-12 eftContainer">
              <div className="d-flex mb-3 align-items-center title-bar">
                <h2>
                  All Agents ({agentList?.length})
                </h2>
                {
                  <div className="ms-lg-auto ms-md-auto">
                    <div className="d-flex gap-3 justify-content-sm-between">
                      <div className='search'>
                        <div className='group'>
                          <select onChange={(e) => changeSearchGroup(e)} value={selectedGroupName}>
                            <option value="">All Group </option>
                            {groupList.map(group => (
                              <option value={group.name}>{group.name} </option>
                            ))}
                          </select>
                        </div>
                        <input type='text' placeholder='Enter the agent name' onChange={(e) => changeSearchText(e)} value={searchText}></input>
                        <button className='search-btn' type='button' onClick={() => getAssistanceList()}>Search</button>
                      </div>
                      <button
                        type="submit"
                        className="btn btn-secondary"
                        disabled={!permission.createAgent}
                        onClick={(e) => goChatBot(null)}
                      >
                        Create Agent
                      </button>
                    </div>
                  </div>
                }
              </div>
              <div className="row flex-wrap">
                {agentList?.map((agent: IAgentService) => (
                  <div className="col-12 col-md-3 col-lg-3 mb-4 boxHolder">
                    <div className="box" onClick={(e) => goChatBot(agent)}>
                      <div className="iconHolder">
                        {agent.icon ? (
                          <img
                            src={`data:${agent.icon};base64,${agent.icon}`}
                            alt=""
                          />
                        ) : (
                          <img
                            src={require("../../common/images/chatbox.png")}
                            alt=""
                          />
                        )}
                      </div>
                      <div className="textHolder">{agent.name}</div>
                      {
                        <div className="icons">
                          <button
                            className="close"
                            title="Delete"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDeleteAssistant(agent.id ? agent.id : "");
                            }}
                          >
                            <LuTrash2 />
                          </button>
                        </div>
                      }
                    </div>
                  </div>
                ))}
                {agentList?.length === 0 && (
                  <>
                    <div className="col-12 col-md-12 mt-5">
                      <p className="text-center">
                        {" "}
                        There are no records to display
                      </p>
                    </div>
                  </>
                )}
              </div>
              {totalCount > pageSize &&
                <ReactPaginate
                  className="pagination"
                  breakLabel="..."
                  nextLabel=">"
                  onPageChange={handlePageClick}
                  pageCount={Math.ceil(totalCount / pageSize)}
                  previousLabel="<"
                  renderOnZeroPageCount={null}
                />
              }
            </div>
          </div>
          <ThreeDotLoader visibility={showLoader}></ThreeDotLoader>
        </>
      )}
    </div>
  );
};

export default Home;
